import React, { useState } from "react";
import {
  Icon,
  Radio,
  Button,
  Checkbox,
  Textfield,
} from "@element/react-components";
import axios from "axios";
import Confirmation from "../components/Confirmation";
import { basePath, httpHeaders } from "../utils/constants";

const Sidebartabs = (props) => {
  const [toggletab, setToggletab] = useState({
    tab: 0,
    show: false,
  });
  const [innertoggletab, setInnertoggletab] = useState({
    tab: 0,
    show: false,
  });
  const [bulkredactvalues, setBulkredactvalues] = useState({
    infovalue: "PPD",
    text: "",
  });
  const [descriptionvalues, setDescriptionvalues] = useState({
    infovalue: "PPD",
    text: "",
  });
  const [openConfirm, setOpenConfirm] = useState({
    show: false,
    type: "",
    warning: "",
    message: "",
  });

  const changetoggleTabs = (no) => {
    if (toggletab.tab !== no) {
      setToggletab({
        tab: no,
        show: true,
      });
    } else {
      setToggletab({
        tab: 0,
        show: false,
      });
    }
  };

  const changeInnertoggleTabs = (no) => {
    if (innertoggletab.tab !== no) {
      setInnertoggletab({
        tab: no,
        show: true,
      });
    } else {
      setInnertoggletab({
        tab: 0,
        show: false,
      });
    }
  };

  const OutertabHeader = (no, title) => {
    return (
      <>
        <div
          className={
            "tabhead " +
            (toggletab.tab === no && toggletab.show ? "active" : "")
          }
          onClick={() => changetoggleTabs(no, toggletab.show)}
        >
          {toggletab.tab === no && toggletab.show ? (
            <Icon icon="arrow_right" iconSize="medium" />
          ) : (
            <Icon icon="arrow_drop_down" iconSize="medium" />
          )}
          <span>{title}</span>
        </div>
      </>
    );
  };

  const InnertabHeader = (no, title) => {
    return (
      <>
        <div
          className={
            "innertabhead " +
            (innertoggletab.tab === no && innertoggletab.show ? "active" : "")
          }
          onClick={() => changeInnertoggleTabs(no, innertoggletab.show)}
        >
          <span>{title}</span>
          {innertoggletab.tab === no && innertoggletab.show ? (
            <Icon
              icon="keyboard_arrow_up"
              iconSize="medium"
              className="lightgreycolor"
            />
          ) : (
            <Icon
              icon="keyboard_arrow_down"
              iconSize="medium"
              className="lightgreycolor"
            />
          )}
        </div>
      </>
    );
  };

  const bulkredactApi = () => {
    const formData = {
      documentId: props.documentid,
      term: bulkredactvalues.text,
      redactionType: bulkredactvalues.infovalue,
    };
    props.setloader(true);
    axios({
      url: `${basePath}/bulkSearch`,
      method: "POST",
      headers: httpHeaders,
      data: formData,
    })
      .then((res) => {
        const temp = [...res.data, ...props.notes];
        props.setnotes(temp);
        setBulkredactvalues({
          infovalue: "",
          text: "",
        });
        props.setloader(false);
        changeInnertoggleTabs(4);
      })
      .catch((err) => {
        console.log(err);
        props.setloader(false);
      });
  };

  const descredactApi = () => {
    const formData = {
      documentId: props.documentid,
      description: descriptionvalues.text,
      redactionType: descriptionvalues.infovalue,
    };
    props.setloader(true);
    axios({
      url: `${basePath}/descriptionSearch`,
      method: "POST",
      headers: httpHeaders,
      data: formData,
    })
      .then((res) => {
        const temp = [...res.data, ...props.notes];
        props.setnotes(temp);
        setDescriptionvalues({
          infovalue: "",
          text: "",
        });
        props.setloader(false);
        changeInnertoggleTabs(4);
      })
      .catch((err) => {
        console.log(err);
        props.setloader(false);
      })
      .then(() => {
        props.setloader(false);
      });
  };

  const confirmedCancel = () => {
    setOpenConfirm({
      show: false,
      type: "",
      warning: "",
      message: "",
    });
  };

  const confirmedAction = (note) => {
    props.removehighlight(note);
    setOpenConfirm({
      show: false,
      type: "",
      warning: "",
      message: "",
    });
  };

  const confirmedReset = () => {
    props.resethighlights();
    setOpenConfirm({
      show: false,
      type: "",
      warning: "",
      message: "",
    });
  };

  return (
    <>
      <div className="innertabframe">
        <div className="redactbtns">
          <Button
            type="submit"
            variant="outlined"
            className="bluebtn"
            label="Save"
            onClick={() => props.onsave()}
          />
          <Button
            type="submit"
            variant="outlined"
            className="bluebtn"
            label="Proposed"
            onClick={() => props.proposeddocument()}
          />
          <Button
            type="submit"
            variant="outlined"
            className="bluebtn"
            label="Final"
            onClick={() => props.generatedocument()}
          />
        </div>
        {OutertabHeader(1, "Add Redactions Manually")}
        {toggletab.tab === 1 && toggletab.show && (
          <div className="tabbody pad10 manualredacttab">
            <Button
              type="submit"
              label="ADD PPD"
              variant="outlined"
              onClick={() => props.settingredactiontype("PPD")}
              className={props.redactiontype === "PPD" ? "bluebtn" : "whitebtn"}
            />
            <Button
              type="submit"
              label="ADD CCI"
              variant="outlined"
              onClick={() => props.settingredactiontype("CCI")}
              className={props.redactiontype === "CCI" ? "bluebtn" : "whitebtn"}
            />
          </div>
        )}
        {OutertabHeader(2, "Find Text and Redact")}
        {toggletab.tab === 2 && toggletab.show && (
          <div className="tabbody pad10">
            {/* Inner tab 1 starts */}
            {InnertabHeader(1, "Text Search")}
            {innertoggletab.tab === 1 && innertoggletab.show && (
              <div className="innertabbody bulkredact">
                <p>Choose Redaction Type</p>
                <div className="blueradio">
                  <Radio
                    label="PPD"
                    value={"PPD"}
                    name="radio-group"
                    checked={bulkredactvalues.infovalue === "PPD"}
                    onChange={() =>
                      setBulkredactvalues({
                        ...bulkredactvalues,
                        infovalue: "PPD",
                      })
                    }
                  />
                  <Radio
                    label="CCI"
                    value={"CCI"}
                    name="radio-group"
                    checked={bulkredactvalues.infovalue === "CCI"}
                    onChange={() =>
                      setBulkredactvalues({
                        ...bulkredactvalues,
                        infovalue: "CCI",
                      })
                    }
                  />
                </div>
                <Textfield
                  id="bulkredacttxtId"
                  name="bulkredacttxt"
                  variant="outlined"
                  label="Search here"
                  style={{ width: "100%" }}
                  placeholder="Search here"
                  value={bulkredactvalues.text}
                  onChange={(e) =>
                    setBulkredactvalues({
                      ...bulkredactvalues,
                      text: e.target.value,
                    })
                  }
                />
                <p className="redactcaption">Limit to 32 characters</p>
                <div className="bulkredactbtns">
                  <Button
                    type="submit"
                    label="Clear All"
                    variant="outlined"
                    className="whitebtn"
                    onClick={() =>
                      setBulkredactvalues({
                        infovalue: "",
                        text: "",
                      })
                    }
                  />
                  <Button
                    type="submit"
                    label="Redact"
                    variant="outlined"
                    className="bluebtn"
                    onClick={() => bulkredactApi()}
                  />
                </div>
              </div>
            )}
            {/* Inner tab 1 ends */}
            {/* Inner tab 2 starts */}
            {InnertabHeader(2, "Description Search")}
            {innertoggletab.tab === 2 && innertoggletab.show && (
              <div className="innertabbody bulkredact">
                <p>Choose Redaction Type</p>
                <div className="blueradio">
                  <Radio
                    label="PPD"
                    value={"PPD"}
                    name="radio-group"
                    checked={descriptionvalues.infovalue === "PPD"}
                    onChange={() =>
                      setDescriptionvalues({
                        ...descriptionvalues,
                        infovalue: "PPD",
                      })
                    }
                  />
                  <Radio
                    label="CCI"
                    value={"CCI"}
                    name="radio-group"
                    checked={descriptionvalues.infovalue === "CCI"}
                    onChange={() =>
                      setDescriptionvalues({
                        ...descriptionvalues,
                        infovalue: "CCI",
                      })
                    }
                  />
                </div>
                <Textfield
                  id="desctxtId"
                  name="desctxt"
                  variant="outlined"
                  label="Search here"
                  style={{ width: "100%" }}
                  placeholder="Search here"
                  value={descriptionvalues.text}
                  onChange={(e) =>
                    setDescriptionvalues({
                      ...descriptionvalues,
                      text: e.target.value,
                    })
                  }
                />
                <p className="redactcaption">Limit to 32 characters</p>
                <div className="bulkredactbtns">
                  <Button
                    type="submit"
                    label="Clear All"
                    variant="outlined"
                    className="whitebtn"
                    onClick={() =>
                      setDescriptionvalues({
                        infovalue: "",
                        text: "",
                      })
                    }
                  />
                  <Button
                    type="submit"
                    label="Redact"
                    variant="outlined"
                    className="bluebtn"
                    onClick={() => descredactApi()}
                  />
                </div>
              </div>
            )}
            {/* Inner tab 2 ends */}
          </div>
        )}
        {OutertabHeader(3, "Advanced Tools")}
        {toggletab.tab === 3 && toggletab.show && (
          <div className="tabbody pad10">
            {/* Inner tab 3 starts */}
            {InnertabHeader(3, "Change Marking Style in Document")}
            {innertoggletab.tab === 3 && innertoggletab.show && (
              <div className="innertabbody"></div>
            )}
            {/* Inner tab 3 ends */}
            {/* Inner tab 4 starts */}
            {InnertabHeader(4, "Reset Functions")}
            {innertoggletab.tab === 4 && innertoggletab.show && (
              <div className="innertabbody"></div>
            )}
            {/* Inner tab 4 ends */}
            {/* Inner tab 5 starts */}
            {InnertabHeader(5, "Remove Pages as Out-of-Scope")}
            {innertoggletab.tab === 5 && innertoggletab.show && (
              <div className="innertabbody"></div>
            )}
            {/* Inner tab 5 ends */}
          </div>
        )}
        {OutertabHeader(4, "Redactions in the document")}
        {toggletab.tab === 4 && toggletab.show && (
          <div className="tabbody">
            {props.notes.length === 0 && <p> No Redacted text</p>}
            {props.notes.map((note, nIndex) => {
              return (
                <div key={`note${nIndex}`} className="notelist">
                  <div className="notelistcheck">
                    <Checkbox {...props} checked={false} onChange={() => {}} />
                  </div>
                  <div
                    className="notelistquote"
                    onClick={() =>
                      props.jumptohighlightarea(note.highlightAreas[0])
                    }
                  >
                    <span>
                      {note.quote !== ""
                        ? note.quote
                        : "Image: " + note.content}{" "}
                      {`(`}
                      {note.redactionType}
                      {`)`}
                    </span>
                  </div>
                  {/*note.content*/}
                  <div className="notelistdelete">
                    <a
                      onClick={() =>
                        setOpenConfirm({
                          show: true,
                          type: note,
                          warning:
                            "This will delete the current highlight and cannot be undone.",
                          message:
                            "Are you sure you want to delete the highlight?",
                        })
                      }
                    >
                      X
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="notebtns">
          <Button
            type="submit"
            variant="outlined"
            className="whitebtn"
            label="Reset All Redactions"
            onClick={() =>
              setOpenConfirm({
                show: true,
                type: "",
                warning:
                  "This will delete all the highlights and cannot be undone.",
                message: "Are you sure you want to delete all the highlights?",
              })
            }
          />
        </div>
      </div>
      {openConfirm.show && (
        <>
          <Confirmation
            message={openConfirm.message}
            error={openConfirm.warning}
            cancelfn={confirmedCancel}
            submitfn={
              openConfirm.type === "" ? confirmedReset : confirmedAction
            }
            type={openConfirm.type}
          />
        </>
      )}
    </>
  );
};

export default Sidebartabs;
