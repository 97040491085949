import { useEffect } from "react";
import {
  Card,
  // CardTitle,
  // Table,
  // TableBody,
  // TableRow,
  // TableCell,
  // LabelBadge,
  // LinearProgress,
} from "@element/react-components";
import {
  // BarChart,
  // Cell,
  // Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
// import { scaleOrdinal } from "d3-scale";
// import { schemeCategory10 } from "d3-scale-chromatic";
import Metric from "../components/Metric";
import Dashboardtable from "../components/Dashboardtable";
import "../styles/dashboard.scss";

// const colors = scaleOrdinal(schemeCategory10).range();

// const getPath = (x, y, width, height) => {
//   return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
//     y + height / 3
//   }
//     ${x + width / 2}, ${y}
//     C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
//     x + width
//   }, ${y + height}
//     Z`;
// };

// const TriangleBar = (props) => {
//   const { fill, x, y, width, height } = props;
//   return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
// };

//  api call
// const barData = [
//   {
//     name: "Page A",
//     uv: 4000,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//   },
// ];
//  api call needed

//  api call needed
// const referncedata = [
//   {
//     name: "Redaction 1",
//     date: "09/20/2023",
//     title: "User 1",
//   },
//   {
//     name: "Redaction 2",
//     date: "09/19/2023",
//     title: "User 2",
//   },
//   {
//     name: "Redaction 3",
//     date: "09/18/2023",
//     title: "User 3",
//   },
//   {
//     name: "Redaction 4",
//     date: "09/17/2023",
//     title: "User 4",
//   },
// ];

const Dashboard = () => {
  const isLoggedIn = useIsAuthenticated();

  const linechartData = [
    {
      month: "Jan",
      documents: 2,
    },
    {
      month: "Feb",
      documents: 5,
    },
    {
      month: "Mar",
      documents: 18,
    },
    {
      month: "April",
      documents: 13,
    },
    {
      month: "May",
      documents: 9,
    },
    {
      month: "June",
      documents: 4,
    },
    {
      month: "July",
      documents: 2,
    },
    {
      month: "Aug",
      documents: 7,
    },
    {
      month: "Sep",
      documents: 14,
    },
  ];

  const metricData = [
    {
      title: "This month",
      count: 60,
      data1: "5%",
      data2: "95%",
      label1: "Pending",
      label2: "Completed",
    },
    {
      title: "Last month",
      count: 235,
      data1: "70%",
      data2: "30%",
      label1: "Pending",
      label2: "Completed",
    },
  ];

  useEffect(() => {
    // console.log("here", isLoggedIn);
  }, []);

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <div className="dash_holder">
        <div className="dash_frames">
          <Card variant="raised" height="auto">
            {/* <CardTitle
              placement="top"
              primaryText={
                <h3 style={{ color: "#23668C" }}>
                  My documents processed trend
                </h3>
              }
            /> */}
            <div className="cardhead">
              <div className="cardtitle">
                <label>My documents processed trend</label>
              </div>
              <div className="cardright">
                <span
                  className="colorblock"
                  style={{ backgroundColor: "#66B512" }}
                ></span>
                <span className="colordesc"># of documents processed</span>
              </div>
            </div>
            <div className="linechartframe">
              <div className="linechartyaxis"># of documents processed</div>
              <div className="linechartcontainer">
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    // width={500}
                    // height={300}
                    data={linechartData}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid />
                    <XAxis dataKey="month" />
                    <YAxis dataKey="documents" />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="linear"
                      dataKey="documents"
                      stroke="#66B512"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Card>
          {/* <div>
            <Card variant="raised" height="auto" style={{ width: "300px" }}>
              <CardTitle
                placement="top"
                primaryText={
                  <h4 style={{ color: "#23668C" }}>Recent Redactions</h4>
                }
              />
              <BarChart
                width={250}
                height={320}
                data={barData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Bar
                  dataKey="uv"
                  fill="#8884d8"
                  shape={<TriangleBar />}
                  label={{ position: "top" }}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                  ))}
                </Bar>
              </BarChart>
            </Card>
          </div> */}
        </div>
        <div className="dash_frames">
          <Card variant="raised" height="auto" bodyAlignment="left-center">
            <div className="cardhead">
              <div className="cardtitle">
                <label>My Pending Reviews</label>
              </div>
              <div className="cardright"></div>
            </div>
            {/*
            <CardTitle
              placement="top"
              primaryText={
                <h3 style={{ color: "#23668C" }}>Pending Reviews</h3>
              }
            />
            <h4 style={{ color: "#23668C", padding: "0 1rem" }}>Metric 1</h4>
            <div>
              <div style={{ width: "500px" }}>
                <div className="mainDiv">
                  <div className="childDiv" style={{ width: `15%` }}></div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ color: "#23668C", padding: "0 1rem" }}>Metric 2</h4>
              <div>
                <div style={{ width: "500px" }}>
                  <div className="mainDiv">
                    <div className="childDiv" style={{ width: `99.01%` }}></div>
                  </div>
                </div>
              </div>
            </div>
            */}
            <Metric data={metricData} />
          </Card>
        </div>
        <div className="dash_frames">
          <Card variant="raised" height="auto" bodyAlignment="left-center">
            <Dashboardtable />
            {/* 
            <CardTitle
              placement="top"
              primaryText={<h3 style={{ color: "#23668C" }}>Redactions</h3>}
            />
            <Table style={{ padding: "0 1rem" }}>
              <TableBody>
                {referncedata.map((val, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{val.name}</TableCell>
                      <TableCell>Created On :{val.date}</TableCell>
                      <TableCell>
                        <LabelBadge
                          label={val.title}
                          labelType="string"
                          themeColor="danger"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            */}
          </Card>
        </div>
      </div>
    );
  }
};
export default Dashboard;
