import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

import User from "../assets/User.svg";
import Avatar from "../assets/Avatar.png";
import BayerLogo from "../assets/BayerLogo.svg";
import GroupLogo from "../assets/GroupLogo.svg";
import Dashboard from "../assets/Dashboard.svg";
import Documents from "../assets/Documents.svg";
// import Reviews from "../assets/Reviews.svg";
// import Notifications from "../assets/Notifications.svg";
import Notifications from "./Notifications";
import {
  Menu,
  Icon,
  // Button,
  // NotificationBadge,
} from "@element/react-components";
import { useMsal } from "@azure/msal-react";

import About from "./About";
// import Popup from "reactjs-popup";
// import UserProfile from "./UserProfile";
// import SignInSignOutButton from "./SignInSignOutButton";
import "../styles/header.scss";

const Header = () => {
  const [userName, setUserName] = useState();
  const { instance, accounts } = useMsal();
  const [open, setOpen] = useState(false);

  // const openMenu = useCallback(() => {
  //   setOpen(!open);
  // }, [open]);
  // const [open1, setOpen1] = useState(false);
  // const openMenuprof = useCallback(() => {
  //   setOpen1(!open1);
  // }, [open]);
  // const closeMenu = useCallback(() => {
  //   setOpen(false);
  // }, []);

  const isLoggedIn = useIsAuthenticated();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    const currentAccount = accounts[0];
    if (currentAccount) {
      setUserName(currentAccount.name);
    }
  }, [accounts]);

  return (
    <>
      <div className="header">
        <div className="header_logo">
          <img src={BayerLogo} alt="Bayer Logo" className="bayerheader_logo" />
          {isLoggedIn && (
            <img
              src={GroupLogo}
              alt="Group Logo"
              className="groupheader_logo"
            />
          )}
        </div>
        <ul className="header_home">
          {isLoggedIn ? (
            <>
              <li className="header_home_items">
                <img src={Dashboard} alt="Dashboard Logo" />
                <Link to="/dashboard">
                  {" "}
                  <h3 className="header_home_text">DASHBOARD</h3>
                </Link>
              </li>
              <li className="header_home_items">
                <img src={Documents} alt="Documents Logo" />
                <Link to="/documents">
                  {" "}
                  <h3 className="header_home_text">DOCUMENTS</h3>
                </Link>
              </li>
              {/* <li className="header_home_items">
                <img src={Reviews} alt="Reviews Logo" />
                <Link to="/reviews/1">
                  {" "}
                  <h3 className="header_home_text">REVIEWS & REDACTIONS</h3>
                </Link>
              </li> */}
              <li className="header_home_items">
                <Notifications />
                {/* <Popup
                  trigger={
                    <button
                      variant="text"
                      style={{ backgroundColor: "#10384f", border: "none" }}
                    >
                      {" "}
                      <img src={Notifications} alt="Notification" />
                    </button>
                  }
                  position="bottom right"
                >
                  <div>Notifications</div>
                </Popup> */}
              </li>
              <li className="header_home_items user_profile">
                <img src={User} alt="User Profile" className="user_icon" />
                <div className="user">
                  <ul>
                    <li className="user_list">
                      <img className="user_avtar" src={Avatar} alt="avatar" />
                      <h3>{userName}</h3>
                    </li>
                    <li className="user_list">
                      <Icon icon="question_mark" iconSize="small" />
                      Help & Support
                    </li>
                    <li className="user_list_inside">
                      <Icon
                        icon="comment_bank"
                        iconSize="small"
                        iconType="filled"
                      />
                      FAQ
                    </li>
                    <li className="user_list_inside">
                      <Icon
                        icon="bookmark"
                        iconSize="small"
                        iconType="filled"
                      />
                      Guides
                    </li>
                    <li className="user_list">
                      <button
                        onClick={handleLogout}
                        style={{ border: "none", backgroundColor: "white" }}
                      >
                        <Icon
                          icon="logout"
                          iconSize="small"
                          iconType="filled"
                        />
                        <span>Log out</span>
                      </button>
                    </li>
                  </ul>
                </div>
                {/* <Menu
                  anchorCorner="BOTTOM_LEFT"
                  direction="ltr"
                  trigger={
                    <button
                      onClick={openMenuprof}
                      variant="text"
                      style={{ backgroundColor: "#10384f", border: "none" }}
                    >
                      <img src={User} alt="User Profile" />
                    </button>
                  }
                  open={open1}
                  surfaceOnly
                  hoistToBody
                  onClose={closeMenu}
                >
                  <div>
                    <UserProfile />
                  </div>
                </Menu> */}
              </li>
            </>
          ) : (
            <>
              {/* <li className="header_home_items">
                <Icon
                  icon={"login"}
                  style={{ paddingRight: "10px" }}
                  variant="color-on-dark"
                />
                <Link to="/">
                  <h3 className="header_home_text">Login</h3>
                </Link>
              </li>
              <li className="header_home_items">
                <Menu
                  anchorCorner="BOTTOM_LEFT"
                  direction="ltr"
                  trigger={
                    <div>
                      <button
                        onClick={() => setOpen(!open)}
                        className="abtbtn"
                        variant="text"
                      >
                        <Icon icon={"info"} style={{ paddingRight: "5px" }} />{" "}
                        ABOUT
                      </button>
                    </div>
                  }
                  open={open}
                  surfaceOnly
                  hoistToBody
                  onClose={() => setOpen(false)}
                >
                  <div>
                    <About />
                  </div>
                </Menu>
              </li> */}
            </>
          )}
        </ul>
      </div>
    </>
  );
};
export default Header;
