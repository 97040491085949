import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  highlightPlugin,
  MessageIcon,
  HighlightArea,
  RenderHighlightContentProps,
  RenderHighlightsProps,
  RenderHighlightTargetProps,
} from "@react-pdf-viewer/highlight";
import { Card, Button } from "@element/react-components";
import {
  PrimaryButton,
  Position,
  Tooltip,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  SelectionMode,
  selectionModePlugin,
} from "@react-pdf-viewer/selection-mode";

import Navbarreviews from "./Navbarreviews";
import Sidebartabs from "./Sidebartabs";
import { basePath, httpHeaders } from "../utils/constants";
import { testHighlights as _testHighlights } from "../ui-components/test-highlights";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/selection-mode/lib/styles/index.css";
import "../styles/sidebar.scss";

const Highlightexample = ({
  fileUrl,
  setLoader,
  createdAt,
  documentId,
  requirement,
  documentName,
}) => {
  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState([]);
  let noteaction = 1;
  const [noteactions, setNoteactions] = useState([]);
  const [redactiontype, setRedactiontype] = useState("PPD");
  let noteId = notes.length;

  const noteEles = new Map();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: SelectionMode.Text,
  });

  const testHighlights = _testHighlights;
  const PRIMARY_PDF_URL = "https://browse.arxiv.org/pdf/1708.08021.pdf";
  const searchParams = new URLSearchParams(document.location.search);
  const initialUrl = searchParams.get("url") || PRIMARY_PDF_URL;
  const [highlights, setHighlights] = useState(
    testHighlights[initialUrl] ? [...testHighlights[initialUrl]] : []
  );
  const lastIndex = basePath.lastIndexOf("/") || basePath.lastIndexOf("\\");
  const FILE_PATH = basePath.substring(0, lastIndex);

  const documentIds = useParams().id;
  const getRedactions = () => {
    setLoader(true);
    axios({
      url: `${basePath}/redact_new`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentId: documentIds,
      },
    })
      .then((res) => {
        setLoader(false);
        setNotes(res.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const generatedocument = async () => {
    setLoader(true);
    await axios({
      url: `${basePath}/generateDocumentNew`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentPath: fileUrl,
        redactions: notes,
      },
    })
      .then((response) => {
        setLoader(false);
        fetch(FILE_PATH + "/" + response.data.docPath).then((resp) => {
          resp.blob().then((blob) => {
            console.log(blob, "blob here");
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Result.pdf";
            alink.click();
          });
        });
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const proposeddocument = async () => {
    setLoader(true);
    await axios({
      url: `${basePath}/generateDocumentNew`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentPath: fileUrl,
        redactions: notes,
        version: "proposal",
      },
    })
      .then((response) => {
        setLoader(false);
        fetch(FILE_PATH + "/" + response.data.docPath).then((resp) => {
          resp.blob().then((blob) => {
            console.log(blob, "blob here");
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Result.pdf";
            alink.click();
          });
        });
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const onsave = async () => {
    setLoader(true);
    await axios({
      url: `${basePath}/save_redactions_new`,
      method: "POST",
      headers: httpHeaders,
      data: {
        documentId: documentId,
        redactions: notes,
      },
    })
      .then((response) => {
        getRedactions();
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const resethighlights = () => {
    setHighlights([]);
    setNotes([]);
  };

  useEffect(() => {
    getRedactions();
  }, []);

  const renderHighlightTarget = (props) => (
    <div
      style={{
        background: "#eee",
        display: "flex",
        position: "absolute",
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: "translate(0, 8px)",
        zIndex: 1,
      }}
    >
      <Tooltip
        position={Position.TopCenter}
        target={
          <Button onClick={props.toggle}>
            <MessageIcon />
          </Button>
        }
        content={() => <div style={{ width: "100px" }}>Add highlights</div>}
        offset={{ left: 10, top: -18 }}
      />
    </div>
  );

  const renderHighlightContent = (props) => {
    const addNote = () => {
      {
        console.log(props.highlightAreas, "high");
      }
      console.log(props, "render highlight");
      // if (message !== "") {
      const note = {
        id: ++noteId,
        content: "manual", // message
        highlightAreas: props.highlightAreas,
        quote: props.selectedText,
        redactionType: redactiontype,
      };
      console.log(note);
      setNotes([note].concat(notes));
      setNoteactions(
        [{ id: noteaction++, action: "add", item: note }].concat(noteactions)
      );
      props.cancel();
      // }
    };

    return (
      <div
        style={{
          zIndex: 1,
          padding: "8px",
          background: "#fff",
          borderRadius: "2px",
          position: "absolute",
          border: "1px solid rgba(0, 0, 0, .3)",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        }}
      >
        {/* <div>
          <textarea
            rows={3}
            style={{
              border: "1px solid rgba(0, 0, 0, .3)",
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div> */}
        <div
          style={{
            display: "flex",
            marginTop: "8px",
          }}
        >
          <div style={{ marginRight: "8px" }}>
            <Button variant="outlined" onClick={addNote}>
              Add
            </Button>
          </div>
          <Button onClick={props.cancel}>Cancel</Button>
        </div>
      </div>
    );
  };

  const jumpToNote = (note) => {
    if (noteEles.has(note.id)) {
      noteEles.get(note.id).scrollIntoView();
    }
  };

  const renderHighlights = (props) => (
    <div>
      {notes.map((note, noteIndex) => (
        <React.Fragment key={`notesframe_${noteIndex}`}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={`div${idx}`}
                // className={
                //   note.content === "manual" && note.redactionType === "PPD"
                //     ? "redactoverlayppd"
                //     : note.content === "manual" && note.redactionType === "CCI"
                //     ? "redactoverlaycci"
                //     : "redactoverlay"
                // }
                style={
                  /*
                  note.content === "manual" && note.redactionType === "PPD"
                    ? Object.assign(
                        {},
                        {
                          background: "lightblue",
                          opacity: 1,
                        },
                        props.getCssProperties(area, props.rotation)
                      )
                    : note.content === "manual" && note.redactionType === "CCI"
                    ? Object.assign(
                        {},
                        {
                          background: "black",
                          opacity: 1,
                        },
                        props.getCssProperties(area, props.rotation)
                      )
                    : 
                    */
                  Object.assign(
                    {},
                    {
                      background: "yellow",
                      opacity: 0.4,
                    },
                    props.getCssProperties(area, props.rotation)
                  )
                }
                onClick={() => jumpToNote(note)}
                ref={(ref) => {
                  noteEles.set(note.id, ref);
                }}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const removehighlight = (item) => {
    var removedNote = notes.filter((note) => {
      return note.id === item.id;
    });
    var tempNotes = notes.filter((note) => {
      return note.id !== item.id;
    });
    setNotes(tempNotes);
    setNoteactions(
      [{ id: noteaction++, action: "delete", item: removedNote }].concat(
        noteactions
      )
    );
  };

  return (
    <div className="sidebar">
      <Card style={{ width: "350px" }}>
        <div className="redactions_frame">
          <h3>Redaction Tools</h3>
          <Sidebartabs
            notes={notes}
            onsave={onsave}
            setnotes={setNotes}
            setloader={setLoader}
            documentid={documentId}
            redactiontype={redactiontype}
            requirementValue={requirement}
            removehighlight={removehighlight}
            resethighlights={resethighlights}
            proposeddocument={proposeddocument}
            generatedocument={generatedocument}
            settingredactiontype={setRedactiontype}
            jumptohighlightarea={jumpToHighlightArea}
          />
        </div>
      </Card>
      <Card
        variant="raised"
        bodyAlignment="left-center"
        style={{
          minHeight: "100vh",
          width: "65vw",
          position: "relative",
          border: "0 !important",
          background: "none",
          boxShadow: "none",
        }}
      >
        <Navbarreviews createdAt={createdAt} documentName={documentName} />
        <div className="viewerframe">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={fileUrl}
              plugins={[
                highlightPluginInstance,
                defaultLayoutPluginInstance,
                selectionModePluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Card>
    </div>
  );
};

export default Highlightexample;
